<template>
  <div class="bg-app vh-100 forms pt-0" :class="$vuetify.breakpoint.xs ? 'py-0' : 'py-5'">
    <v-container class="py-0" :class="$vuetify.breakpoint.xs ? 'px-1' : 'px-3'" fluid>
      <v-alert dense v-if="!$online" type="warning" icon="mdi-alert" class="alert-offline text-headline">
        {{ $t("t_no_connection") }}
      </v-alert>
      <div v-else>
        <v-card flat tile>
          <v-card-text class="d-flex pb-0">
            <p class="headline mb-0">{{ $t("v_account.t_account_settings") }}</p>
          </v-card-text>
        </v-card>

        <v-card class="mt-6">
          <v-toolbar elevation="0">
            <v-toolbar-title
              ><router-link to="/issue" style="color: inherit; text-decoration: none">{{
                $t("v_account.t_preferences")
              }}</router-link></v-toolbar-title
            >
          </v-toolbar>
          <v-card-text class="px-6">
            <v-row class="pb-2" style="align-items: center">
              <v-col cols="12">
                <TheLanguageSwitcher></TheLanguageSwitcher>
              </v-col>
              <v-col cols="6">
                <v-switch v-if="settings" :input-value="$vuetify.theme.dark" @click="changeDarkMode">
                  <template v-slot:label>
                    <span class="pl-3 text--primary">{{ $t("v_account.t_dark_mode") }}</span>
                  </template>
                </v-switch>
              </v-col>
              <v-col cols="6">
                <v-switch
                  v-if="settings"
                  :input-value="settings.timeFormat24Hour"
                  @click="changeTimeFormat"
                  :hint="$t('v_account.t_24_hour')"
                  persistent-hint
                >
                </v-switch>
              </v-col>
            </v-row>
            <v-row class="pb-2">
              <v-col cols="12">
                <span>{{ $t("v_account.t_set_preferred_timezone") }}</span>
                <v-radio-group @change="changeTimeZone" v-model="settings.timeZone" class="mt-1">
                  <v-radio value="America/Los_Angeles" :label="$t('v_account.t_america_los_angeles')"></v-radio>
                  <v-radio value="America/Denver" :label="$t('v_account.t_america_denver')"></v-radio>
                  <v-radio value="America/Chicago" :label="$t('v_account.t_america_chicago')"></v-radio>
                  <v-radio value="America/New_York" :label="$t('v_account.t_america_new_york')"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card>
          <v-toolbar elevation="0">
            <v-toolbar-title>{{ $t("v_account.t_user_profile") }}</v-toolbar-title>
          </v-toolbar>
          <v-alert v-if="notificationProfile" type="error" class="ma-4">
            {{ notificationProfile }}
          </v-alert>
          <v-card-text class="pt-6">
            <v-form ref="registerForm" v-model="formValid">
              <TheImageUploader />
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="fields.firstName" :rules="[rules.required]" maxlength="20" required>
                    <template #label
                      >{{ $t("v_account.t_first_name") }} <span class="required"><strong>*</strong></span></template
                    >
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="fields.lastName" :rules="[rules.required]" maxlength="20" required
                    ><template #label
                      >{{ $t("v_account.t_last_name") }} <span class="required"><strong>*</strong></span></template
                    ></v-text-field
                  >
                </v-col>
                <v-col cols="12">
                  <PhoneInput v-model="fields.phoneNumber"></PhoneInput>
                </v-col>
                <v-col cols="12" sm="7" md="7">
                  <v-text-field
                    disabled
                    v-model="fields.companyName"
                    :rules="[rules.required]"
                    :label="$t('v_account.t_company')"
                    maxlength="100"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    disabled
                    v-model="fields.email"
                    :rules="emailRules"
                    :label="$t('v_account.t_email')"
                    required
                  ></v-text-field>
                </v-col>

                <v-col class="d-flex ml-auto mr-3" cols="12">
                  <v-spacer></v-spacer>
                  <v-btn :disabled="!formValid" color="primary" @click="onSubmit">{{ $t("t_update") }}</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>

        <v-card class="mt-6">
          <v-toolbar elevation="0" tile>
            <v-toolbar-title>{{ $t("v_account.t_change_password") }}</v-toolbar-title>
          </v-toolbar>
          <v-alert v-if="notificationPassword" type="error" class="ma-4">
            {{ notificationPassword }}
          </v-alert>
          <v-card-text>
            <v-form ref="changePasswordForm" v-model="formPasswordValid">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="creds.currentPassword"
                    :append-icon="showpw ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showpw ? 'text' : 'password'"
                    name="current-password"
                    :label="$t('v_account.t_current_password')"
                    :hint="$t('v_account.t_at_least_8_characters')"
                    @click:append="showpw = !showpw"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="creds.newPassword"
                    :append-icon="showpw ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.min]"
                    :type="showpw ? 'text' : 'password'"
                    name="new-password"
                    :label="$t('v_account.t_new_password')"
                    :hint="$t('v_account.t_at_least_8_characters')"
                    @click:append="showpw = !showpw"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    block
                    v-model="creds.confirmPassword"
                    :append-icon="showpw ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.min, passwordMatch]"
                    :type="showpw ? 'text' : 'password'"
                    name="confirm-password"
                    :label="$t('v_account.t_confirm_new_password')"
                    @click:append="showpw = !showpw"
                  ></v-text-field>
                </v-col>

                <v-col class="d-flex ml-auto mr-3" cols="12">
                  <v-spacer></v-spacer>
                  <v-btn :disabled="!formPasswordValid" color="primary" @click="onPasswordSubmit">{{
                    $t("v_account.t_change_password")
                  }}</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </div>
    </v-container>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { Trans } from "@/plugins/Translation";
import TheImageUploader from "@/components/TheImageUploader";
import TheLanguageSwitcher from "@/components/TheLanguageSwitcher";
import PhoneInput from "@/components/PhoneInput";
import Helpers from "@/mixins/helpers";
import { Settings } from "luxon";

export default {
  name: "Account",
  metaInfo: {
    title: "Account",
  },
  components: {
    TheImageUploader,
    PhoneInput,
    TheLanguageSwitcher,
  },
  mixins: [Helpers],
  computed: {
    ...mapGetters("settings", ["settings"]),
    ...mapGetters("user", ["user"]),
    passwordMatch() {
      return () => this.creds.newPassword === this.creds.confirmPassword || "Password must match";
    },
  },
  data() {
    return {
      formValid: false,
      formPasswordValid: false,
      creds: {},
      fields: {
        languageCode: Trans.getUserLang().lang ? Trans.getUserLang().lang : "en-US",
      },
      emailRules: [(v) => !!v || "Required", (v) => /.+@.+\..+/.test(v) || "Email must be valid"],
      showpw: false,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => (v && v.length >= 8) || "Min 8 characters",
      },
      notificationPassword: null,
      notificationProfile: null,
      timeZones: [
        {
          value: Settings.defaultZoneName,
          text: this.getZoneName(Settings.defaultZoneName),
        },
        {
          value: "America/Los_Angeles",
          text: this.$t("v_account.t_america_los_angeles"),
        },
        {
          value: "America/Denver",
          text: this.$t("v_account.t_america_denver"),
        },
        {
          value: "America/Chicago",
          text: this.$t("v_account.t_america_chicago"),
        },
        {
          value: "America/New_York",
          text: this.$t("v_account.t_america_new_york"),
        },
      ],
    };
  },
  methods: {
    ...mapActions("auth", ["changePassword", "logout"]),
    ...mapActions("settings", ["setLang", "getUser", "updateUserProfile"]),
    ...mapMutations("settings", ["toggleDarkMode", "toggleTimeFormat", "setTimeZone"]),
    async onSubmit() {
      if (this.$refs.registerForm.validate()) {
        let user = {};
        user.id = this.fields.id;
        user.firstName = this.fields.firstName;
        user.lastName = this.fields.lastName;
        user.phoneNumber = this.fields.phoneNumber;

        await this.updateUserProfile(user);

        this.notificationProfile = "";
        this.$snackbar.showMessage({
          content: this.$t("c_snackbar.t_profile_updated"),
          color: "success",
          timeout: "",
        });
      }
    },
    async onPasswordSubmit() {
      if (this.$refs.changePasswordForm.validate()) {
        this.creds.email = this.fields.email;

        let params = {};
        params.oldPassword = this.creds.currentPassword;
        params.newPassword = this.creds.newPassword;

        await this.changePassword(params);

        this.notificationPassword = "";
        this.$snackbar.showMessage({
          content: this.$t("c_snackbar.t_success_password_changed"),
          color: "success",
          timeout: "",
        });

        this.logout();
      }
    },
    getUserProfile() {
      this.getUser().then((r) => {
        this.fields = r.data;
      });
    },
    changeTimeZone(timeZone) {
      this.setTimeZone(timeZone);
      this.$snackbar.showMessage({
        content: this.$t("c_snackbar.t_success_settings_saved"),
        color: "success",
        timeout: "",
      });
    },
    changeTimeFormat() {
      this.toggleTimeFormat();
      this.$snackbar.showMessage({
        content: this.$t("c_snackbar.t_success_settings_saved"),
        color: "success",
        timeout: "",
      });
    },
    changeDarkMode() {
      this.toggleDarkMode();
      this.$snackbar.showMessage({
        content: this.$t("c_snackbar.t_success_settings_saved"),
        color: "success",
        timeout: "",
      });
    },
    getZoneName(zone) {
      if (zone == "America/Los_Angeles") {
        return this.$t("v_account.t_america_los_angeles");
      } else if (zone == "America/Denver") {
        return this.$t("v_account.t_america_denver");
      } else if (zone == "America/Chicago") {
        return this.$t("v_account.t_america_chicago");
      } else if (zone == "America/New_York") {
        return this.$t("v_account.t_america_new_york");
      } else {
        return zone;
      }
    },
  },
  mounted() {
    this.getUserProfile();
  },
};
</script>
