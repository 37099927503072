<template>
  <div class="langage-select">
    <v-select
      :class="small ? 'small' : ''"
      v-model="localeComputed"
      :items="supportedLanguages"
      item-value="code"
      item-text="name"
      @change="changeLanguage()"
      :hint="$t('t_select_language')"
      persistent-hint
      single-line
      :hide-details="hideDetails"
      :height="height"
    ></v-select>
  </div>
</template>
<script>
import { Trans } from "@/plugins/Translation";
import { mapActions } from "vuex";
import { init } from "@services";

export default {
  computed: {
    supportedLanguages() {
      return Trans.supportedLanguagesObj;
    },
    currentLanguage() {
      return Trans.currentLanguage;
    },
    localeComputed: {
      get() {
        return this.locale ? this.locale : this.currentLanguage;
      },
      set(newLang) {
        this.locale = newLang;
      },
    },
  },
  props: {
    hideDetails: {
      type: Boolean,
      default: false,
    },
    height: {
      type: Number,
      default: 30,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      locales: Trans.supportedLanguages,
      locale: "",
    };
  },
  methods: {
    ...mapActions("settings", ["setLang"]),

    async changeLanguage() {
      const lang = this.localeComputed;
      await Trans.changeLanguage(lang);
      await this.setLang(lang);
      init(); //reset accept-language headers
    },

    isCurrentLanguage(lang) {
      return lang === this.currentLanguage;
    },
  },
};
</script>

<style lang="scss">
.LanguageSwitcher {
  margin-bottom: 1rem;
  margin: 15px;
  color: #aaa;
  border: 1px solid #ccc;
}
.langage-select {
  .small {
    font-size: 13px;
  }
}
</style>
