<template>
  <v-text-field
    v-model="phone"
    :tabindex="tabIndex"
    v-bind="opts"
    :rules="rules"
    :loading="loading"
    maxlength="15"
    type="tel"
    class="phone"
    :hint="$t('c_phoneinput.t_select_country_and_enter_phone_number')"
    :disabled="disabled"
    @change="filterKeyDigits"
  >
    <template #label
      >{{ $t("c_phoneinput.t_phone_number_with_area_code") }} <span class="required"><strong>*</strong></span></template
    >
    <template v-slot:prepend-inner>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on" class="country-selector pa-2">
            <flag v-if="country" style="font-size: 18px" :iso="country.value" :squared="false"></flag>
            <v-icon v-else>mdi-earth</v-icon>
            <v-icon right>mdi-menu-down</v-icon>
          </div>
        </template>
        <v-list dense class="country-list">
          <v-list-item @click="country = usa"
            ><v-list-item-content
              ><div>
                <flag style="font-size: 24px" :iso="usa.value" :squared="false"></flag>
                <span class="ml-2">{{ usa.text }}</span>
              </div>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-for="(item, index) in items" :key="index" @click="country = item">
            <v-list-item-content>
              <div>
                <flag style="font-size: 24px" :iso="item.value" :squared="false"></flag>
                <span class="ml-2">{{ item.text }}</span>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <template v-slot:append>
      <v-icon>mdi-phone</v-icon>
    </template>
  </v-text-field>
</template>

<style lang="scss">
.phone {
  .v-input__slot {
    padding: 0 !important;
  }
}

.country-list {
  height: 300px;
  overflow-y: auto;
}

.country-selector {
  &:hover {
    background-color: #808080;
    border-radius: 2px;
  }

  &:active {
    background-color: #606060;
  }
}
</style>

<script>
import Helpers from "@/mixins/helpers";
import { LocalizationService } from "@services";
import FlagIcon from "vue-flag-icon";
import Vue from "vue";
Vue.use(FlagIcon);
export default {
  name: "phone-input",
  props: {
    tabIndex: Number,
    iso: String,
    value: String,
    required: Boolean,
    disabled: Boolean,
    opts: Object,
  },

  mixins: [Helpers],

  data() {
    return {
      loading: true,
      items: [],
      country: {
        callingCode: "1",
        text: "United States of America",
        value: "US",
      },
      phone: null,
      rules: [
        (v) => {
          if (v) {
            return /^[0-9]\d{10,15}$/.test(`${this.country?.callingCode}${v}`) || "Invalid phone number";
          }
          return true;
        },
        () => !!this.country || "Country is required",
      ],
      allCallingCodes: {}, // hash table to speed up search
      usa: {
        callingCode: "1",
        text: "United States of America",
        value: "US",
      },
    };
  },

  computed: {
    valid() {
      return !this.rules.map((rule) => rule(this.phone)).some((result) => typeof result === "string");
    },

    fullPhoneNum() {
      if (this.country && this.phone) {
        return `+${this.country.callingCode}${this.phone}`;
      }
      return null;
    },
  },

  watch: {
    country() {
      this.$emit("input", this.fullPhoneNum);
    },

    phone() {
      this.$emit("input", this.fullPhoneNum);
    },

    value(v) {
      if (v) {
        this.init(v);
      } else {
        this.phone = null;
      }
    },
  },

  methods: {
    init(phone) {
      for (const key of Object.keys(this.allCallingCodes)) {
        const callingCode = this.allCallingCodes[key];
        const cc = `+${callingCode}`;
        if (phone.startsWith(cc)) {
          this.phone = phone.substring(cc.length);
          this.country = {
            value: callingCode === "1" ? "US" : key,
            callingCode,
          }; // default +1 to US
          break;
        }
      }
    },
  },

  created: async function () {
    const r = await LocalizationService.getAllCountries();
    if (!r.data.hasErrors) {
      this.items = r.data
        .filter((country) => country.countryCode !== "AD" && country.countryCode !== "EH")
        .map((country) => {
          const iso = country.countryCode;
          const nameEn = country.countryNameEn;
          const callingCode = country.countryCallingCode;
          this.allCallingCodes[iso] = callingCode;
          const localNames = country.countryNameLocal.split(",");
          let localName;
          if (iso === "MA") {
            localName = localNames[localNames.length - 1];
          } else if (iso === "AF") {
            localName = "د افغانستان اسلامي امارت";
          } else {
            localName = localNames[0];
          }
          if (!localName) {
            localName = nameEn;
          }
          const text = nameEn === localName ? nameEn : `${nameEn} (${localName})`;
          return { text, value: iso, callingCode };
        });
    }
    if (this.iso) {
      const value = this.iso.toUpperCase();
      const callingCode = this.allCallingCodes[value];
      if (callingCode) {
        this.country = { value, callingCode };
      }
    }
    if (this.value) {
      this.init(this.value);
    }
    if (this.required) {
      this.rules.unshift((v) => !!v || "Phone number is required");
    }
    this.loading = false;
  },
};
</script>
